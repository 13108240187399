<template>
  <label class="result-output">
    <div class="result-output__header" @click.passive="setTextArea">
      <span>Результат</span>
      <button
          class="result-output__button"
          type="button"
      >
        {{ isTextAreaExtra ? 'TextArea' : 'Inputs' }}
      </button>
    </div>

    <textarea
        v-if="isTextAreaExtra"
        v-model="strExtra"
        class="form-control form-control--textarea"
        rows="10"
        placeholder="Список пуст"
    />

    <template v-else>
      <div
          v-for="(item, itemIndex) of arrExtra"
          :key="itemIndex"
          class="result-output__input-wrap"
      >
        <input
            :value="item"
            class="form-control"
            type="text"
        />
        <button
            class="result-output__button result-output__button--fill result-output__copy"
            type="button"
            @click="copyText(item)"
        >
          Copy
        </button>
      </div>
      <div v-if="!arrExtra.length" class="result-output__empty">

        Список пуст
      </div>
    </template>
  </label>
</template>

<script>
export default {
  name: "ResultOutput",
  props: {
    strExtra: {
      type: String,
      required: true
    },
    arrExtra: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isTextAreaExtra: true
    }
  },
  methods: {
    setTextArea () {
      this.isTextAreaExtra = !this.isTextAreaExtra
    },
    copyText (item = '') {
      if (!this.arrExtra.length) return
      navigator.clipboard.writeText(item);
    }
  }
}
</script>

<style lang="scss">
.result-output {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    span + span {
      margin-left: 4px;
    }
  }

  &__button {
    color: var(--button);
    border: none;
    border-radius: 3px;
    background-color: transparent;
    cursor: pointer;
  }

  &__button--fill {
    padding: 9px;
    color: #fff;
    background-color: var(--button);

    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 0.7;
    }
  }

  &__input-wrap {
    position: relative;

    & + & {
      margin-top: 10px;
    }

    .form-control {
      padding-right: 55px;
    }
  }

  &__copy {
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 14px;
    transform: translateY(-50%);
  }

  &__empty {
    text-align: center;
    padding-top: 12px;
    border-top: 1px solid var(--border-color-input);
  }
}
</style>
