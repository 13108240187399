const breakIntoWords = function (str) {
    let tempStr = str.trim()
    while (tempStr.includes('  ') || tempStr.includes('\n')) {
        tempStr = tempStr.replace(/\n|\s\s/g, ' ')
    }

    return tempStr.trim() ? tempStr.trim().split(' ') : []
}

export default breakIntoWords
